<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('user.cards/add')" addName="新增在线卡" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.cardno" size="small" clearable placeholder="按卡号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.model" placeholder="按卡模式查询" clearable size="small">
					<el-option label="在线卡" :value="10"> </el-option>
					<el-option label="离线卡" :value="20"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.type" placeholder="按卡类型查询" clearable size="small">
					<el-option label="普通卡" :value="10"> </el-option>
					<el-option label="内部卡" :value="20"> </el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="cardno" title="卡号" align="center" min-width="100px" />
			<!-- <vxe-table-column
        slot="table-item"
        field="pwd"
        title="卡密"
        align="center"
      >
        <template v-slot="{ row }">
          <span>{{row.pwd||'无'}}</span>
        </template>
      </vxe-table-column> -->
			<vxe-table-column slot="table-item" field="card_status" title="状态" align="center" min-width="120px">
				<template v-slot="{ row }">
					<span>{{ types.card_status[row.card_status] || '未知状态' }}</span>
					<br>
					<span style="font-size:10px" v-if="row.report_lose_datetime != null">将于{{row.report_lose_datetime}}挂失</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="model" title="卡模式" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{ types.model[row.model] || '未知卡' }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="type" title="卡类型" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{ types.type[row.type] || '未知类型' }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="phone" title="绑定用户" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{ row.phone || '无' }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="group_name" title="绑定站点" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{ row.group_name || '无' }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="band_time" title="绑定时间" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{ row.band_time || '无' }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="create_time" title="创建时间" align="center" min-width="100px" />

			<vxe-table-column slot="table-item" title="操作" align="center" width="300">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detailOne(row)" v-if="$hasAccess('user.cards/view')">详情</el-button>
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.card/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="reportLoss(row)" v-if="$hasAccess('user.cards/add') && row.card_status == 1 && row.report_lose_datetime == null">挂失</el-button>
					<el-button size="small" plain @click="recoveryCard(row)" v-if="($hasAccess('user.cards/add') && row.card_status == 0) || row.report_lose_datetime !=null"
						style="background:red; color:white">恢复</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<!-- 详情 -->
		<el-dialog title="电卡详情" :visible.sync="detailDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="800px">
			<el-form ref="detailForm" label-width="140px" class="form" size="mini">
				<el-row>
					<el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 10, offset: 1 }">
						<!-- 电卡信息 -->
						<div class="el-block">
							<div class="row-title">电卡信息</div>
							<el-form-item label="卡号：">{{ detailDialogFormData.cardno }}</el-form-item>
							<!-- <el-form-item label="卡密：">{{detailDialogFormData.pwd||'无'}}</el-form-item> -->
							<el-form-item label="卡模式：">{{ types.model[detailDialogFormData.model] || '未知' }}</el-form-item>
							<el-form-item label="卡类型：">{{ types.type[detailDialogFormData.type] || '未知类型' }}</el-form-item>
							<el-form-item label="卡剩余：">{{ detailDialogFormData.balance }}{{ types.btype[detailDialogFormData.type] || '' }}</el-form-item>
							<el-form-item label="创建时间：">{{ detailDialogFormData.create_time }}</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 10, offset: 2 }">
						<!-- 绑定信息 -->
						<div class="el-block">
							<div class="row-title">绑定信息</div>
							<el-form-item label="绑定用户：">{{ detailDialogFormData.nickname || '无' }}</el-form-item>
							<el-form-item label="绑定时间：">{{ detailDialogFormData.band_time || '无' }}</el-form-item>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
			</div>
		</el-dialog>
		<!-- 新增 -->
		<el-dialog title="新增在线卡" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" top="40vh" width="500px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="140px" class="form">
				<el-form-item label="卡号" prop="cardno">
					<el-input v-model="addDialogFormData.cardno" placeholder="请输入10位数卡号" clearable style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="类型" prop="type" >
					<el-radio-group v-model="addDialogFormData.type">
						<el-radio :label="10">普通卡</el-radio>
						<el-radio :label="20">内部卡</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="绑定站点" prop="group_id" >
					<el-select v-model="addDialogFormData.group_id"  size="small" multiple filterable remote
							   reserve-keyword clearable placeholder="请选择站点" v-if="$hasAccess('device.group/view')"
							   :remote-method="remoteGroup" :loading="groupLoading">
						<el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name"
								   :value="item.group_id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()" v-if="$hasAccess('user.cards/add')">新 增 保 存</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑优惠" :visible.sync="editDialogShow" :close-on-click-modal="false"
				   :close-on-press-escape="false" width="600px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="卡号" prop="card_name">
					<el-input v-model="editDialogFormData.cardno" style="width: 50%;" disabled></el-input>
				</el-form-item>
				<el-form-item label="类型" prop="type">
					<el-radio-group v-model="editDialogFormData.type">
						<el-radio :label="10">普通卡</el-radio>
						<el-radio :label="20">内部卡</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="绑定小区" prop="groupIds" >
					<el-select v-model="groupIds" filterable size="small" multiple remote reserve-keyword clearable
							   placeholder="请选择小区" v-if="$hasAccess('device.group/view')" :remote-method="remoteGroup"
							   :loading="groupLoading">
						<el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name"
								   :value="item.group_id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()"
						   :disabled="editDisabled" v-if="$hasAccess('market.card/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
		<!-- 定时挂失 -->
		<el-dialog title="定时挂失" :visible.sync="reportLossDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" top="40vh" width="500px">
			<div class="report-loss-line">
				<p> 挂失类型：</p>
				<el-select v-model="reportLossFormData.type" placeholder="挂失类型" clearable size="small">
					<el-option label="即时" :value="0"> </el-option>
<!--					<el-option label="定时" :value="1"> </el-option>-->
				</el-select>
			</div>
			<div class="report-loss-line" v-if="reportLossFormData.type == 1">
				<p> 选择日期时间：</p>
				<el-date-picker v-model="reportLossFormData.report_lose_datetime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择日期和时间">
				</el-date-picker>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="reportLoseCommit()" v-if="$hasAccess('user.cards/add')">提 交 挂 失</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeReportLose()">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import Tables from "@/components/tables";
	export default {
		name: "cards-cards-list",
		components: {
			Tables,
		},
		data() {
			return {
				// 表格信息
				tableName: "电卡列表",
				isLoading: false,
				tableData: [],
				totalPage: 0,
				groupList: [],
				groupIds: [],
				groupLoading:'',
				searchFormData: {},
				types: {
					model: {
						10: "在线卡",
						20: "离线卡",
					},
					type: {
						10: "普通卡",
						20: "内部卡"
					},
					btype: {
						10: "元",
						20: "次",
						30: "",
					},
					card_status: {
						0: "挂失",
						1: "正常",
					},
				},
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
				// 新增
				addDialogShow: false,
				addDialogFormData: {},
				// 挂失
				reportLossDialogShow: false,
				reportLossFormData: {
					type: 0,
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {},
				editDialogFormOriData: {},
				editNeedData: ['card_id', 'cardno', 'type'],
				// 规则
				rules: {
					cardno: [{
						required: true,
						min: 10,
						max: 10,
						message: "卡号长度必须为10位",
						trigger: "change"
					}],
					num: [{
						required: true,
						min: 1,
						max: 3,
						message: "数量范围为1-999",
						trigger: "change"
					}]
				},
			};
		},
		computed: {
			editDisabled() {
				for (let item of this.editNeedData) {
					if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
						return false
					}
				}
				return false
			}
		},
		mounted() {
			this.init();
		},
		activated() {
			this.init();
		},
		methods: {
			// 初始化请求
			init() {
				this.remoteGroup();
			},
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize,
				};
				this.isLoading = true;
				const searchData = this.searchFormData;
				const res = await this.$api.Cards.GetCardsList(params, searchData);
				this.tableData = res.data;
				this.totalPage = res.total;
				this.isLoading = false;
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {};
			},
			// 重置form
			resetFormData() {
				this.addDialogFormData = { 
					cardno: "",
					num: "1",
					model: 10,
					type: 10
				};
				this.editDialogFormData = {}
				this.editDialogFormOriData = {}
				this.groupIds = [];
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false;
				this.addDialogShow = false;
				this.editDialogShow = false
			},
			// 详情
			async detailOne(row) {
				const params = {
					token: this.$store.state.user.token,
					card_id: row.card_id,
				};
				const res = await this.$api.Cards.GetCardsDetail(params);
				this.detailDialogFormData = res;
				this.detailDialogShow = true;
			},
			async remoteGroup(query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token,
						page: 1,
						size: 1000
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			// 新增
			addOne() {
				this.resetFormData();
				this.addDialogShow = true;
			},
			// 新增保存
			saveAdd() {
				this.$refs.addForm.validate(async (valid) => {
					if (valid) {
						const params = {
							...this.addDialogFormData,
							token: this.$store.state.user.token,
						};
						await this.$api.Cards.AddCard(params);
						this.$notify({
							title: "成功",
							message: "新增成功",
							type: "success",
						});
						this.closeDialog();
						this.$refs.xTable.refreshTable();
					}
				});
			},
			// 编辑保存
			saveEdit() {
				this.editDialogFormData.group_id = this.groupIds.join(',')
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						await this.$api.Cards.EditCard(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 挂失||取消挂失
			recoveryCard(row) {
				this.$confirm("确定要将卡号" + row.cardno + "取消挂失吗", "提示", {
					cancelButtonClass: "btn-custom-cancel",
					confirmButtonText: "取消挂失",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					const params = {
						token: this.$store.state.user.token,
						cardno: row.cardno,
					};
					this.$api.Cards.recoveryCard(params).then((res) => {
						this.$notify({
							title: "成功",
							message: "解除挂失成功",
							type: "success",
						});
						// 刷新
						this.$refs.xTable.refreshTable();
					}).catch((err) => {
						this.closeReportLose();
						if (err.indexOf("成功") > -1) {
							this.$notify({
								title: "成功",
								message: "解除挂失成功",
								type: "success",
							});
							// 刷新
							this.$refs.xTable.refreshTable();
						} else {
							this.$notify({
								title: "失败",
								message: "解除挂失失败",
								type: "error",
							});
						}
					});
				});
			},
			// 编辑
			async editOne(row) {
				this.resetFormData()
				const params = {
					token: this.$store.state.user.token,
					card_id: row.card_id
				}
				const res = await this.$api.Cards.GetCardsDetail(params)
				if (res) {
					const {
						card_id,
						cardno,
						type,
						group_id
					} = res
					this.editDialogFormData = {
						card_id,
						cardno,
						type,
						group_id
					}
					this.editDialogFormOriData = {
						card_id,
						cardno,
						type,
						group_id
					}
				}
				if (res.group_id) {
					const params = {
						token: this.$store.state.user.token,
						size: 1000
					}
					this.$api.Device.GetGroupList(params).then(gres => {
						this.groupList = gres.data
						const groupIds = res.group_id
						for (let i = 0; i < groupIds.length; i++) {
							groupIds[i] = parseInt(groupIds[i])
						}
						this.groupIds = groupIds
					})
				}
				this.editDialogShow = true
			},
			// 挂失
			reportLoss: function (row) {
				this.reportLossDialogShow = true;
				this.reportLossFormData.row = row;
			},
			reportLoseCommit: function () {
				let row = this.reportLossFormData.row;
				this.$confirm("确定要将卡号" + row.cardno + "挂失吗", "提示", {
					cancelButtonClass: "btn-custom-cancel",
					confirmButtonText: "挂失",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					const params = {
						token: this.$store.state.user.token,
						cardno: row.cardno,
						type: this.reportLossFormData.type,
						report_lose_datetime: this.reportLossFormData.report_lose_datetime,
					};
					console.log(params);
					this.$api.Cards.ShellCard(params).then((res) => {
						this.$notify({
							title: "成功",
							message: "挂失成功",
							type: "success",
						});
						// 刷新
						this.closeReportLose();
						this.$refs.xTable.refreshTable();
					}).catch((err) => {
						this.closeReportLose();
						if (err.indexOf("成功") > -1) {
							this.$notify({
								title: "成功",
								message: "挂失成功",
								type: "success",
							});
							// 刷新
							this.$refs.xTable.refreshTable();
						} else {
							this.$notify({
								title: "失败",
								message: "挂失失败",
								type: "error",
							});
						}
					});
				});
			},
			closeReportLose: function () {
				this.reportLossDialogShow = false;
			},
		},
	};
</script>
<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}

	.avatar {
		width: 24px;
		height: 24px;
		display: block;
		margin: 0 auto;
	}

	.avatarD {
		width: 24px;
		height: 24px;
		display: block;
	}

	.report-loss-line {
		p {
			width: 100px;
			text-align: right;
		}

		display: flex;
		align-items: center;
	}
</style>
